/* eslint-disable no-underscore-dangle */

import elementReady from 'element-ready';

import { Highlight } from '../../types';
import type { HighlightResizeState, UnhighlightableReason } from '../../types/highlights';
import { LogLevel } from '../../types/logging';
import { isHTMLElement, isTextNode } from '../../typeValidators';
import nowTimestamp from '../../utils/dates/nowTimestamp';
import { isExtension } from '../../utils/environment';
import getWords from '../../utils/getWords';
import makeLogger from '../../utils/makeLogger';
import type { HighlightElement, TextHighlightElement } from '../types';
import type {
  RangyCharacterRange,
  RangyClassApplier,
  RangyHighlight,
  RangyHighlighter,
  RangyRange,
} from '../types/rangy';
// eslint-disable-next-line import/no-cycle
import cleanUpHtmlForHighlighting from './cleanUpHtmlForHighlighting';
import clearSelection from './clearSelection';
import convertRangeToRangyRange from './convertRangeToRangyRange';
import createElementFromString from './createElementFromString';
import findText from './findText';
import getClosestHTMLElement from './getClosestHTMLElement';
import getHtmlFromRange from './getHtmlFromRange';
import getHtmlFromSelection from './getHtmlFromSelection';
import getTextNodeFromHighlightElement from './getTextNodeFromHighlightElement';
import isElementTypable from './isElementTypable';
import isHighlightNode from './isHighlightNode';
import isImage from './isImage';
// eslint-disable-next-line import/no-cycle
import { deserializeSelection, serializeSelection } from './locationSerializer';
import rangy from './rangy';

const logger = makeLogger(__filename);

type ImageHighlightElement = HTMLImageElement;
export type HighlightResult = {
  characterRange: RangyCharacterRange;
  containsText: boolean;
  elements: HighlightElement[];
  html: string;
  rangyHighlight: RangyHighlight;
  rangeText: string;
};

export default class Renderer {
  static highlightClassName = 'rw-highlight';
  static highlightHoverClassName = `${this.highlightClassName}--hover`;
  static highlightIconWrapperClassName = 'rw-highlight-icon-wrapper';
  static highlightMinimizedIconClassName = 'rw-highlight-annotations-icon';
  static highlightNoteIconClassName = 'rw-highlight-note-icon';
  static highlightResizeHandleClassName = 'rw-highlight-resize-handle';
  static highlightResizeHandleClassNameByEdge = {
    end: `${Renderer.highlightResizeHandleClassName}--end`,
    start: `${Renderer.highlightResizeHandleClassName}--start`,
  };

  static highlightCustomChildrenClasses = [
    Renderer.highlightIconWrapperClassName,
    Renderer.highlightResizeHandleClassName,
  ];

  static highlightTagIconClassName = 'rw-highlight-tag-icon';

  static imageHighlightClassName = 'rw-image-highlight';
  static textHighlightTagName = 'rw-highlight';

  static getHighlightElementSelector(id?: Highlight['id']): string {
    let selectors = [Renderer.textHighlightTagName, `img.${Renderer.imageHighlightClassName}`];
    if (id) {
      selectors = selectors.map((selector) => `${selector}[data-highlight-id="${id}"]`);
    }
    return selectors.join(', ');
  }

  static getHighlightCustomChildSelector(): string {
    return Renderer.highlightCustomChildrenClasses.map((className) => `.${className}`).join(',');
  }

  /*
    Is it a child element we add to highlight elements like the icon-wrapper?
    This checks classes. It doesn't check that it's actually inside a highlight element.
  */
  static isCustomHighlightChild(node: Node): boolean {
    return isHTMLElement(node) && node.matches(Renderer.getHighlightCustomChildSelector());
  }

  static makeResizeHandle(edge: 'end' | 'start'): HTMLElement {
    const element = createElementFromString(Renderer.makeResizeHandleHtml(edge));

    element.addEventListener('dragstart', (event) => {
      event.preventDefault();
      event.stopPropagation();
    });

    return element;
  }

  static makeResizeHandleHtml(edge: 'end' | 'start'): string {
    /*
      Why `&#8288;`? See:

      - https://linear.app/readwise/issue/RW-37936/highlights-cause-words-to-split-and-wrap-sometimes-now
      - https://linear.app/readwise/issue/RW-37928/when-a-mid-paragraph-highlight-starts-on-first-character-of-new-line

      > U+2060 Word-Joiner &#8288; representative by no visible character, it prohibits a line break at its position.

      For all Word-Joiner related code, there is a comment which mentions "Word-Joiner".
    */
    return `<span class="${Renderer.highlightResizeHandleClassName} ${Renderer.highlightResizeHandleClassNameByEdge[edge]}">&#8288;<span class="${Renderer.highlightResizeHandleClassName}__inner-wrapper"></span>&#8288;</span>`;
  }

  canHighlightStartInAnother: boolean;
  classApplier: RangyClassApplier | null;
  containerNode: HTMLElement;

  _highlighterType: string;
  _ongoingRenderToken: number | null = null;
  _rangyHighlighter: RangyHighlighter | null;

  constructor({
    canHighlightStartInAnother,
    containerNode,
  }: {
    canHighlightStartInAnother?: boolean;
    containerNode: HTMLElement;
  }) {
    this.canHighlightStartInAnother = canHighlightStartInAnother ?? false;
    this.classApplier = null;
    this.containerNode = containerNode;
    Renderer.textHighlightTagName = 'rw-highlight';
    Renderer.highlightClassName = 'rw-highlight';
    this._highlighterType = 'textContent';
    this._rangyHighlighter = null;

    this._initRangyHighlighter();
  }

  doesRangeContainHighlight(range: Range): boolean {
    return Boolean(
      createElementFromString(`<div>${getHtmlFromRange(range)}</div>`, [
        Renderer.textHighlightTagName,
      ]).querySelector(Renderer.getHighlightElementSelector()),
    );
  }

  getSelectedLocation(): string {
    if (!this.containerNode.ownerDocument) {
      throw new Error('containerNode has no ownerDocument');
    }
    const win = this.containerNode.ownerDocument.defaultView;
    if (!win) {
      throw new Error('Cannot get window from containerNode');
    }

    const sel = rangy.getSelection(win);

    if (!this.classApplier) {
      throw new Error('classApplier is not set');
    }

    return serializeSelection(this.classApplier, sel, this.containerNode);
  }

  async highlightLocation(
    id: string,
    location: string,
    expectedText?: string,
  ): Promise<HighlightResult> {
    if (!location) {
      throw new Error('location missing');
    }

    await this._initRangyHighlighter();

    const selection = deserializeSelection(
      location,
      this.classApplier as RangyClassApplier,
      this.containerNode,
    );
    if (!selection.rangeCount) {
      throw new Error("Deserialized selection's rangeCount is 0");
    }
    const range = selection.getRangeAt(0).cloneRange();

    const closestAnchorElement = getClosestHTMLElement(selection.anchorNode);
    if (closestAnchorElement?.tagName.toLowerCase() === 'script') {
      throw new Error('Cannot highlight <script>');
    }

    clearSelection();

    if (typeof expectedText === 'string') {
      const selectionText = selection.toString().trim();
      if (selectionText !== expectedText.trim()) {
        this._log(LogLevel.Error, "highlightLocation: Text at location doesn't match expected text", {
          expectedText,
          location,
          selection,
          selectionText: selection.toString(),
        });
        throw new Error("Text at location doesn't match expected text");
      }
    }

    return this._highlightRange(id, range as RangyRange);
  }

  async highlightText(id: string, text: string): Promise<HighlightResult | undefined> {
    await this._initRangyHighlighter();

    const range = await findText({
      containerNode: this.containerNode,
      logPrefix: `Highlight#${id}: `,
      text,
    });

    if (range) {
      return this._highlightRange(id, range);
    }
  }

  isHighlightableRange(
    range: Range,
    options?: {
      minimumWordCount?: number;
    },
  ): { isValid: boolean; reason?: UnhighlightableReason } {
    const rangyRange = convertRangeToRangyRange(range);

    if (
      !this.containerNode.contains(rangyRange.startContainer) &&
      !this.containerNode.contains(rangyRange.endContainer)
    ) {
      return { isValid: false, reason: 'not-in-container-node' };
    }

    const startElement = isHTMLElement(rangyRange.startContainer)
      ? rangyRange.startContainer
      : rangyRange.startContainer.parentElement;
    const rangeText = rangyRange.toString();

    if (rangeText.length >= 10000) {
      return { isValid: false, reason: 'too-long' };
    }

    const minimumWordCount = options?.minimumWordCount ?? 3;
    if (minimumWordCount) {
      const language = getClosestHTMLElement(
        startElement,
        (htmlElement) => htmlElement.hasAttribute('lang'),
        isExtension ? this.containerNode.ownerDocument.documentElement : this.containerNode,
      )?.getAttribute('lang');

      if (
        getWords(rangeText, language || 'unknown').length < minimumWordCount &&
        !rangyRange.toHtml().includes('<img')
      ) {
        return { isValid: false, reason: 'too-short' };
      }
    }

    return { isValid: true };
  }

  isHighlightableSelection({
    selection = window.getSelection(),
    highlightResizeState,
  }: {
    selection?: Selection | null;
    highlightResizeState: HighlightResizeState;
  }): {
    isValid: boolean;
    reason?: UnhighlightableReason;
  } {
    if (
      !selection?.rangeCount ||
      isElementTypable(document.activeElement) ||
      // Remove Word-Joiner
      (!selection
        .toString()
        .replace(/\u2060/g, '')
        .trim() &&
        !/<img/i.test(getHtmlFromSelection(selection)))
    ) {
      return { isValid: false, reason: 'empty' };
    }

    const range = selection.getRangeAt(0).cloneRange();

    const doesSelectionExistForHighlightResizing = highlightResizeState.status !== 'inactive';
    const canStartInHighlight =
      this.canHighlightStartInAnother || doesSelectionExistForHighlightResizing;

    if (!canStartInHighlight) {
      const startElement =
        selection.anchorNode && isTextNode(selection.anchorNode)
          ? selection.anchorNode.parentElement
          : range.startContainer;

      if (isHighlightNode(startElement)) {
        return { isValid: false, reason: 'cant-start-in-another' };
      }
    }

    const options: Parameters<typeof Renderer.prototype.isHighlightableRange>[1] = {};
    if (doesSelectionExistForHighlightResizing) {
      options.minimumWordCount = 0;
    }
    return this.isHighlightableRange(range, options);
  }

  async removeHighlight({
    elements,
    rangyHighlight,
  }: {
    elements: HTMLElement[];
    rangyHighlight?: RangyHighlight;
  }): Promise<void> {
    const images = Array.from(elements).filter(isImage);
    const containsTextHighlights = elements.length > images.length;
    if (containsTextHighlights && !rangyHighlight) {
      throw new Error('text highlight elements given but rangyHighlight missing');
    }

    /*
      This is using custom logic because using the built-in
      `this._rangyHighlighter.removeHighlights` failed on
      https://www.joelonsoftware.com/2000/04/10/controlling-your-environment-makes-you-happy/
      (after reloading). The built-in method converts the highlight's character
      range, whereas we create ranges from the DOM nodes.
    */

    if (!this._rangyHighlighter) {
      throw new Error('_rangyHighlighter is not set');
    }

    const rangyHighlightIndex = containsTextHighlights
      ? this._rangyHighlighter.highlights.indexOf(rangyHighlight as RangyHighlight)
      : -1;

    /* eslint-disable no-await-in-loop */
    for (const element of elements) {
      element
        .querySelectorAll(Renderer.getHighlightCustomChildSelector())
        .forEach((child) => child.remove());

      if (isImage(element)) {
        await this._unapplyToImage(element as ImageHighlightElement);
      } else {
        await this._unapplyToText(element as TextHighlightElement);
      }
    }
    /* eslint-enable no-await-in-loop */

    if (containsTextHighlights) {
      // eslint-disable-next-line no-param-reassign
      (rangyHighlight as RangyHighlight).applied = false;

      if (rangyHighlightIndex === -1) {
        this._log(
          LogLevel.Warn,
          "Couldn't find highlight in Rangy highlight's internal list, removed anyway",
          rangyHighlight,
        );
      } else {
        this._rangyHighlighter.highlights.splice(rangyHighlightIndex, 1);
      }
    }
  }

  setContainerNode(containerNode: HTMLElement): void {
    this.containerNode = containerNode;
  }

  _createRange(): RangyRange {
    return rangy.createRange(this.containerNode.ownerDocument);
  }

  /*
    We originally only had support for text highlighting via Rangy. Now we also support highlighting images, with text or without.
    Before we do anything, we grab any images in the range and mark them (using a data attribute). Then we run rangy to
    highlight any text. Then we add a class to the images (images can't be partially highlighted so we don't need to introduce any
    new elements). Finally, we return both text highlight elements (<rw-highlight>) and images combined, sorted by document
    position.
  */
  _highlightRange(id: string, range: RangyRange): HighlightResult {
    // Get HTML before we mutate it
    const html = cleanUpHtmlForHighlighting(getHtmlFromRange(range));
    const rangeText = range.toString().trim();

    if (!this._rangyHighlighter) {
      throw new Error('_rangyHighlighter is not set');
    }

    const characterRange = this._rangyHighlighter.converter.rangeToCharacterRange(range);

    const imagesInRange = (
      Array.from(this.containerNode.querySelectorAll('img')) as HTMLImageElement[]
    ).filter((image) => range.containsNode(image, false));

    if (!imagesInRange.length && characterRange.start === characterRange.end) {
      throw new Error('Range is empty');
    }

    const renderToken = Math.random() * nowTimestamp();
    // eslint-disable-next-line no-restricted-syntax
    for (const image of imagesInRange) {
      image.dataset.rwHighlightRenderToken = renderToken.toString();
    }

    const containsText = Boolean(createElementFromString(`<div>${html}</div>`).innerText.trim());
    let rangyHighlight;
    if (containsText) {
      this._ongoingRenderToken = renderToken;
      let newRangyHighlights: RangyHighlight[];

      try {
        newRangyHighlights = this._rangyHighlighter.highlightCharacterRanges(
          Renderer.highlightClassName,
          [characterRange],
          {
            exclusive: true,
          },
        );
        this._ongoingRenderToken = null;
      } catch (e) {
        this._ongoingRenderToken = null;
        throw e;
      }

      if (!newRangyHighlights.length) {
        throw new Error('rangyHighlight not created');
      }

      [rangyHighlight] = newRangyHighlights;
    }

    const imageElements = Array.from(
      this.containerNode.querySelectorAll(`img[data-rw-highlight-render-token="${renderToken}"]`),
    ) as HTMLImageElement[];
    // eslint-disable-next-line no-restricted-syntax
    for (const image of imageElements) {
      image.classList.add(Renderer.imageHighlightClassName);
      image.removeAttribute('data-rw-highlight-render-token');
    }

    const elements: HighlightElement[] = [];

    if (containsText) {
      const textElements = this.containerNode.querySelectorAll<TextHighlightElement>(
        `${Renderer.textHighlightTagName}[data-rw-highlight-render-token="${renderToken}"]`,
      );
      if (!textElements.length) {
        logger.warn('No text elements found after rendering highlight');
      }

      for (const textElement of textElements) {
        textElement.removeAttribute('data-rw-highlight-render-token');
      }

      elements.push(
        ...[...textElements, ...imageElements]
          // Order by DOM position
          // eslint-disable-next-line no-bitwise
          .sort((a, b) => (a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_PRECEDING ? 1 : -1)),
      );
    } else {
      elements.push(...imageElements);
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const element of elements) {
      element.dataset.highlightId = id;
    }

    if (elements.length) {
      const firstElement = elements[0];
      if (
        !isImage(firstElement) &&
        !firstElement.querySelector(`.${Renderer.highlightResizeHandleClassNameByEdge.start}`)
      ) {
        firstElement.prepend(Renderer.makeResizeHandle('start'));
      }

      const lastElement = elements[elements.length - 1];
      if (!isImage(lastElement)) {
        if (!lastElement.querySelector(`.${Renderer.highlightIconWrapperClassName}`)) {
          lastElement.appendChild(
            createElementFromString(
              `<span aria-hidden="true" class="${Renderer.highlightIconWrapperClassName}">
                <svg class="${Renderer.highlightNoteIconClassName}" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 2C3.34315 2 2 3.34315 2 5V9C2 10.6569 3.34315 12 5 12H6L8 15L10 12H11C12.6569 12 14 10.6569 14 9V5C14 3.34315 12.6569 2 11 2H5Z" />
                </svg>
                <svg class="${Renderer.highlightTagIconClassName}" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5858 7.58579L8.58579 2.58579C8.21071 2.21071 7.70201 2 7.17157 2H4C2.89543 2 2 2.89543 2 4V7.17157C2 7.70201 2.21071 8.21071 2.58579 8.58579L7.58579 13.5858C8.36684 14.3668 9.63316 14.3668 10.4142 13.5858L13.5858 10.4142C14.3668 9.63317 14.3668 8.36684 13.5858 7.58579ZM6 7C6.55228 7 7 6.55228 7 6C7 5.44771 6.55228 5 6 5C5.44772 5 5 5.44771 5 6C5 6.55228 5.44772 7 6 7Z" />
                </svg>
              </span>`
                // Remove space whitespace between tags
                .replace(/>\s+</g, '><'),
            ),
          );
        }

        if (!lastElement.querySelector(`.${Renderer.highlightResizeHandleClassNameByEdge.end}`)) {
          lastElement.appendChild(Renderer.makeResizeHandle('end'));
        }
      }
    }
    return {
      characterRange,
      elements,
      containsText,
      html,
      rangeText,
      rangyHighlight: rangyHighlight as RangyHighlight,
    };
  }

  async _initRangyHighlighter(): Promise<void> {
    if (this._rangyHighlighter) {
      return;
    }

    await elementReady('body');

    rangy.init();

    if (!this.containerNode) {
      throw new Error('containerNode is not set');
    }
    if (!this.containerNode.ownerDocument) {
      throw new Error('containerNode has no ownerDocument');
    }

    const rangyHighlighter = rangy.createHighlighter(
      this.containerNode.ownerDocument,
      this._highlighterType,
    );
    this.classApplier = rangy.createClassApplier(Renderer.highlightClassName, {
      elementTagName: Renderer.textHighlightTagName,
      normalize: false,
      onElementCreate: (element) => {
        if (!this._ongoingRenderToken) {
          logger.warn("Rangy ClassApplier's onElementCreate called but there is no _ongoingRenderToken");
          return;
        }
        element.dataset.rwHighlightRenderToken = this._ongoingRenderToken.toString();
      },
      useExistingElements: false,
    });
    rangyHighlighter.addClassApplier(this.classApplier);
    rangyHighlighter.highlights = []; // to be safe
    this._rangyHighlighter = rangyHighlighter;
  }

  // eslint-disable-next-line class-methods-use-this
  _log(logLevel: LogLevel, message: string, context: Parameters<typeof logger.debug>[1]): void {
    logger[logLevel](`[Highlighter] ${message}`, context);
  }

  async _unapplyToImage(element: ImageHighlightElement): Promise<void> {
    // eslint-disable-next-line no-restricted-syntax
    for (const className of Array.from(element.classList)) {
      element.classList.remove(Renderer.imageHighlightClassName);
      if (className.startsWith(Renderer.highlightClassName)) {
        element.classList.remove(className);
      }
    }
  }

  async _unapplyToText(element: TextHighlightElement): Promise<void> {
    const range = this._createRange();
    const textNode = getTextNodeFromHighlightElement(element);
    if (textNode) {
      range.selectNodeContents(textNode);
    } else {
      // There should be a text node, but just in case
      range.selectNodeContents(element);
    }

    if (!this.classApplier) {
      throw new Error('classApplier is not set');
    }

    /*
      Imagine you highlight a sentence in a paragraph. If normalize is false when undoToRange is
      called, then it won't merge adjacent text nodes after the highlight element is removed.
      I.e. the paragraph will have three text nodes instead of one. This will break highlighting
      of the whole paragraph for example.
    */
    const originalNormalize = this.classApplier.normalize;
    this.classApplier.normalize = true;
    this.classApplier.undoToRange(range);
    this.classApplier.normalize = originalNormalize;

    if (this.classApplier.isAppliedToRange(range)) {
      logger.error('Class Applier says highlight is still applied after undoToRange call');
    }
  }
}
