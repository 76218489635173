import { undoDocumentAction } from 'shared/foreground/stateUpdaters/transientStateUpdaters/undo';
import type { UserEventWithDataUpdate } from 'shared/types';
import getServerBaseUrl from 'shared/utils/getServerBaseUrl.platform';
import requestWithAuth from 'shared/utils/requestWithAuth.platformIncludingExtension';

import makeExtensionLogger from '../common/makeExtensionLogger';
import onRequestError from './onRequestError';

const logger = makeExtensionLogger(__filename);

export default async function sendStateUpdateToApi(event: UserEventWithDataUpdate): Promise<void> {
  logger.debug(event.name, { event });

  try {
    await requestWithAuth(`${getServerBaseUrl()}/reader/api/state/update/`, {
      body: JSON.stringify({
        events: [event],
      }),
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */
        'Content-Type': 'application/json',
        // Errors if User-Agent is set
        'X-Requested-With': 'Readwise Highlighter browser extension',
        /* eslint-enable @typescript-eslint/naming-convention */
      },
      method: 'POST',
    });
  } catch (error) {
    await onRequestError({
      action: 'update',
      additionalLogContext: { event },
      error,
    });
    undoDocumentAction(event.id, null);
    throw error;
  }
}
