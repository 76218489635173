import makeLogger from 'shared/utils/makeLogger';

import isRunningInExtensionBackgroundOrExtensionPage from './isRunningInExtensionBackgroundOrExtensionPage';

export default function makeExtensionLogger(name: string, options?: Parameters<typeof makeLogger>[1]) {
  return makeLogger(name, {
    shouldPrintLogsInProduction: isRunningInExtensionBackgroundOrExtensionPage(),
    shouldLog: true,
    ...options,
  });
}
