import '../networkDetector.platform';

import type { ClientState } from '../types';
import { PersistentStateLoadingState } from '../types';
import makeLogger from '../utils/makeLogger';
import { rxAlert } from './alert.platform';
// eslint-disable-next-line import/no-cycle
import database from './database';
import { updatePersistentStateLoadingState } from './methods';
import onDatabaseSchemaConflict from './onDatabaseSchemaConflict';
// eslint-disable-next-line import/no-cycle
import onLoggedOut from './onLoggedOut.platform';
// eslint-disable-next-line import/no-cycle
import background from './portalGates/toBackground';
// eslint-disable-next-line import/no-cycle
import { initClientData } from './stateUpdaters/clientStateUpdaters/readerSettings';
// eslint-disable-next-line import/no-cycle
import { initPersistentState } from './stateUpdaters/persistentStateUpdaters/infrastructureRelated';

const logger = makeLogger(__filename);

const getAndSetClientState = async () => {
  const clientData = await background.getCacheItem<ClientState>('clientData');
  await initClientData(clientData);
};

export default async function setUpStateTree(): Promise<void> {
  let setUpInitialStateResult: Awaited<ReturnType<typeof background.setUpInitialState>>;

  try {
    logger.debug('Start: background.setUpInitialState');
    await updatePersistentStateLoadingState(PersistentStateLoadingState.HasNotStarted);
    await database.initialize({ onSchemaConflictError: onDatabaseSchemaConflict });

    // Pull all our cached data from disk. Skip the list cache and clientData if we're minimizing state.
    logger.debug('Start: fetching');

    getAndSetClientState();

    setUpInitialStateResult = await background.setUpInitialState();
    logger.debug('End: background.setUpInitialState');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    await updatePersistentStateLoadingState(PersistentStateLoadingState.Failed);
    if (['Could not establish connection. Receiving end does not exist.'].includes(err.message)) {
      logger.error('error setting up initial state', { err });
      return;
    } else {
      if (err.response?.status === 401 || err.message?.includes('401 for')) {
        onLoggedOut();
      } else if (err.reason) {
        logger.error('error setting up initial state', { err });
        rxAlert(
          `Couldn't initialize the app. Would you like to clear your local data and restart? \n\n Reason: ${err.reason}`,
          async () => {
            await database.clearUninitializedDb();
          },
        );
      } else {
        logger.error('error setting up initial state', { err });
        rxAlert(
          `Unknown error in initializing the app. Would you like to clear your local data and restart?\n\nReason: ${err.message}`,
          async () => background.clearAllLocalData(),
        );
      }
      return;
    }
  }

  if (!setUpInitialStateResult) {
    // eslint-disable-next-line no-alert
    alert(`Unknown error in initializing the app`);
    return;
  }

  await initPersistentState(setUpInitialStateResult.persistentState);

  await background.pollLatestState(15);

  // Quick and easy check for if the user has access to Reader.
  // We use .catch here so that initialization doesn't block on getAndSetProfileData:
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  background.getAndSetProfileData().catch((e: any) => {
    if (e?.response?.status === 401) {
      onLoggedOut();
    } else {
      throw e;
    }
  });

  // kick off backend document syncs on page load, so we can get the latest articles
  setTimeout(async () => {
    await background.triggerCloudSyncs();
  }, 1000);
}
