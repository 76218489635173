import foregroundEventEmitter from 'shared/foreground/eventEmitter';
import Toaster from 'shared/foreground/Toaster';
import delay from 'shared/utils/delay';
import {
  ReadwiseFetchClientError,
  ReadwiseFetchNetworkConnectivityError,
  ReadwiseFetchServerError,
} from 'shared/utils/Errors';

import makeExtensionLogger from '../common/makeExtensionLogger';

const logger = makeExtensionLogger(__filename);

export default async function onRequestError({
  action = 'network request',
  additionalLogContext = {},
  error,
}: {
  action?: string;
  additionalLogContext?: { [key: string]: unknown };
  error: Error | unknown;
}) {
  if (!(error instanceof Error)) {
    return;
  }

  const toastCreationOptions: Parameters<Toaster['createToast']>[0] = {
    category: 'error',
    content: `Reader ${action} failed`,
  };

  const logContext: { [key: string]: unknown } = { ...additionalLogContext };

  if (error instanceof ReadwiseFetchClientError || error instanceof ReadwiseFetchServerError) {
    logContext.responseStatus = error.response.status;

    toastCreationOptions.buttonText = 'Reload';
    toastCreationOptions.onButtonClick = () => {
      // @ts-expect-error this is fine
      window.location = window.location.href;
    };

    if (error instanceof ReadwiseFetchClientError) {
      toastCreationOptions.content += '. Tab may be out of sync. Please reload and try again';
      toastCreationOptions.duration = false;
      try {
        logContext.responseJson = await error.response.json();
      } catch (e) {
        // Ignore
      }
    }
  } else if (error instanceof ReadwiseFetchNetworkConnectivityError) {
    logContext.offline = true;
    toastCreationOptions.content += ' due to a network connectivity error';
  }

  (async () => {
    await delay(50); // To allow for any successful toast to get registered first
    foregroundEventEmitter.emit('extension:removeAllToasts');
    foregroundEventEmitter.emit('extension:createToast', toastCreationOptions);
  })();

  logger.error('API request error', { ...logContext, error });
}
